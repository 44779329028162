.splash-table table {
  width: 100%;
  /* background-color: hsl(220deg 26% 24%); */
  border-radius: 6px;
  color: whitesmoke;
  padding: 8px;
}

.splash-table table tr:nth-child(odd){
  background-color: hsl(220deg 26% 24%);
  color: #fff;
}

.splash-table th {
  background-color: hsl(220deg 26% 32%);
  color: whitesmoke;
  text-align: left;
}

.splash-table tr,
.splash-table td,
.splash-table th {
  padding: 16px 24px;
}
@media screen and (min-width: 768px) {
  .splash-table td {
    min-width: 310px;
  }
}