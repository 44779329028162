.modal {
  align-items: center;
  bottom: 0;
  display: none;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: hidden;
  /* padding: .4rem; */
  /* position: fixed; */
  right: 0;
  top: 0;
}

.modal:target,
.modal.active {
  display: -ms-flexbox;
  display: flex;
  opacity: 1;
  z-index: 400;
}

.modal:target .modal-overlay,
.modal.active .modal-overlay {
  background: rgba(247, 248, 249, 0.75);
  bottom: 0;
  cursor: default;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.modal:target .modal-container,
.modal.active .modal-container {
  animation: slide-down 0.2s ease 1;
  z-index: 2;
}

.modal.modal-sm .modal-container {
  max-width: 320px;
  padding: 0 0.4rem;
}

.modal.modal-lg .modal-overlay {
  background: #fff;
}

.modal.modal-lg .modal-container {
  box-shadow: none;
  max-width: 960px;
}

.modal-container {
  background: #fff;
  border-radius: 0.2rem;
  box-shadow: 0 0.2rem 0.5rem rgba(48, 55, 66, 0.3);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 15px;
  max-height: 75vh;
  max-width: 640px;
  padding: 0 0.8rem;
  width: 100%;
}

.modal-container.modal-fullheight {
  max-height: 100vh;
}

.modal-container .modal-header {
  color: #303742;
  padding: 0.8rem;
}

.modal-container .modal-body {
  overflow-y: auto;
  padding: 0.8rem;
  position: relative;
}

.modal-container .modal-footer {
  padding: 0.8rem;
  text-align: right;
}

/**/
.modal.modal-popup {
  z-index: 100;
}

.modal-container {
  margin-top: 2rem;
}

.modal-popup .modal-container {
  min-width: 360px;
}

.modal-container.right,
.modal-container.left {
  position: fixed;
  top: 0px;
  margin-top: 55px;
  max-height: calc(100vh - 80px);
  width: auto;
}
.modal-container.right {
  top: 70px;
  right: 10px;
  margin-top: 0;
}
.modal-container.left {
  left: 10px;
}

.modal-container {
  max-height: 90vh;
}
.modal-container .modal-body {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
.modal-body figure {
  margin: 0;
}
.modal-lg .modal-body figure img,
.modal-popup .modal-body figure img {
  width: 300px;
  height: 300px;
  margin-bottom: 10px;
  border-radius: 3px;
  box-shadow: 0px 2px 6px 2px rgb(0 0 0 / 15%);
}
.modal-popup .modal-body figure img {
  margin-top: -15px;
  object-fit: cover;
}

.btn.btn-sm {
  font-size: 0.9rem;
  height: 1.8rem;
  padding: 0.1rem 0.4rem;
  border: 0.05rem solid #689acb;
  border-radius: 3px;
}

.btn:disabled,
.btn[disabled] {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}

.h5 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 10px;
}

/** MODAL TO BOTTOM OF SCREEN ON SMALL SCREENS **/
@media screen and (max-width: 768px) {
  .modal-container,
  .modal-container.right,
  .modal-container.left {
    margin-top: 0;
    max-height: 50%;
    width: 100%;
    max-width: 100%;
    top: 50%;
    right: 0;
    left: 0;
    min-height: 50%;
  }
}
