/*******************/
/* MAPBOX CONTROLS */
/* GENERAL TWEAKS  */
/*******************/

:root {
  --border-radius: 3px;
}

.mapboxgl-map {
  /* border-radius: 10px; */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-family: inherit;
  /* width: calc(100% - 20px);
  height: calc(100% - 20px) */
}

.mapboxgl-ctrl-top-right {
  /* top: 10px; */
  right: 0;
}
.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  background-color: transparent;
}
.mapboxgl-ctrl.box-shadow button {
  box-shadow: 0 0 10px 2px #0000001a;
}

.mapboxgl-ctrl-group button,
.mapboxgl-ctrl-geocoder.mapboxgl-ctrl.mapboxgl-ctrl-geocoder--collapsed {
  display: block;
  padding: 0;
  outline: none;
  border: none;
  border-radius: var(--border-radius);
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: none;
  width: 36px;
  height: 36px;
  opacity: 1;
}

.mapboxgl-ctrl-group:not(:empty) {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%); /*chakra ui sm box shadow*/
  background: transparent;
}

.mapboxgl-ctrl-group button {
  background-color: white;
}
.mapboxgl-ctrl-group button,
.mapboxgl-ctrl-group button:first-child,
.mapboxgl-ctrl-group button:last-child {
  border-radius: var(--border-radius);
}

.mapboxgl-ctrl button:not(:disabled):hover {
  background-color: rgba(255, 255, 255, 1);
  outline: solid 1px black;
}

/*******************/
/* GEOCODER        */
/*******************/

.mapboxgl-ctrl-group button,
.mapboxgl-ctrl-geocoder.mapboxgl-ctrl.mapboxgl-ctrl-geocoder--collapsed {
  background-color: white;
}
.mapboxgl-ctrl-geocoder {
  font-family: inherit;
}

.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder .suggestions {
  box-shadow: none;
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl.mapboxgl-ctrl-geocoder--collapsed {
  background-color: white;
  border: none;
}

.mapboxgl-ctrl-geocoder {
  width: 33.3333%;
  font-size: 15px;
  line-height: 20px;
  max-width: 360px;
}
.mapboxgl-ctrl-geocoder--input {
  height: 36px;
  padding: 6px 35px;
}

.mapboxgl-ctrl-geocoder--icon-search {
  left: 7px;
  width: 20px;
  height: 20px;
  top: 8px;
}

.mapboxgl-ctrl-geocoder--collapsed .mapboxgl-ctrl-geocoder--icon-search {
  left: 6px;
  top: 6px;
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
  width: 30px;
  min-width: 30px;
}

.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder .suggestions {
  box-shadow: 0 0 10px 2px #0000001a;
}

.mapboxgl-ctrl-geocoder--suggestion.listing {
  background-color: khaki;
  padding: 1rem;
  margin: -14px;
}

.mapboxgl-ctrl-geocoder .suggestions > .active > a > .listing,
.mapboxgl-ctrl-geocoder--suggestion.listing:hover,
.mapboxgl-ctrl-geocoder--suggestion.listing:active,
.mapboxgl-ctrl-geocoder--suggestion.listing:focus {
  background-color: yellow;
}

.mapboxgl-ctrl-attrib-button {
  background-color: white;
  top: -2px;
}

/*******************/
/* NAVIGATION      */
/*******************/

.mapboxgl-ctrl-zoom-in,
.mapboxgl-ctrl-zoom-out {
  border-radius: var(--border-radius);
  margin-bottom: 4px;
}

/** GEOCODER **/
.mapboxgl-ctrl-geocoder .suggestions > li > a {
  padding: 10px;
}
