:root {
  --size: 80px;
}

.mapboxgl-popup .tooltip {
  display: flex;
  min-width: 180px;
  max-width: 100vw;
  justify-content: space-around;
}

.circle-outer {
  height: var(--size);
  width: var(--size);
  border-radius: 50%;
  background: conic-gradient(#86BF34 270deg, #D9EBBF calc(270deg + 0.5deg) 100%);
}

.circle-outer2 {
  height: var(--size);
  width: var(--size);
  border-radius: 50%;
  background: conic-gradient(#86BF34 342deg, #D9EBBF calc(342deg + 0.5deg) 100%);
}

.circle-inner {
  height: 84%;
  width: 84%;
  top: 8%;
  left: 8%;
  position: relative;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  font-weight: bold;
  /* font-size: 16px; */
}
