pre {
  outline: 1px solid #ccc;
  padding: 10px;
  /* margin: 15px; */
}
.string {
  color: green;
}
.number {
  color: goldenrod;
}
.boolean {
  color: firebrick;
}
.null {
  color: darkslategray;
}
.key {
  color: darkblue;
}
