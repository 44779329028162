@import url("https://fonts.googleapis.com/css2?family=Material+Icons+Outlined");
@import url("https://fonts.googleapis.com/css2?family=Material+Icons");

/**
STYLES FOR POPUP MODAL NEED TO MOVE THIS TO A REACT COMPONENT
**/

.text-muted {
  opacity: 0.8;
}

.text-bold {
  font-weight: 600;
}

.text-small {
  font-size: 0.9em;
}

.text-center {
  text-align: center;
}

.d-inline-flex {
  display: inline-flex;
}

.divider {
  border-bottom: solid thin black;
  opacity: 0.1;
  height: 1px;
  width: 100%;
  margin: 10px 0;
}

.material-icons-outlined,
.material-icons {
  margin: 0 6px 6px 0;
}

.btn {
  border-radius: 0.2rem !important;
}

.btn:hover {
  background-color: whitesmoke;
}

.btn:active,
.btn:focus {
  outline: solid 3px lightblue;
}

.modal-body a {
  text-decoration: underline;
}

.map-fullscreen .modal-popup .modal-container.right {
  top: 10px;
}
