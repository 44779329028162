:root {
  font-size: 16px;
}

.chakra-slider__thumb {
  border: solid thin lightgray !important;
}

summary {
  cursor: pointer;
}

select,
option {
  cursor: pointer;
}

.hpa-table td {
  font-size: 14px;
}

.prem-table th {
  text-align: center;
  font-weight: bold;
}

.prem-table td {
  padding: 0.6rem 0.4rem;
  border-bottom: none;
}

/*LEGENDS*/
.rv-discrete-color-legend-item.horizontal {
  display: inline-block;
  padding: 9px 5px;
}

.rv-discrete-color-legend-item.horizontal .rv-discrete-color-legend-item__color {
  margin-left: 20px;
  margin-bottom: 15px;
}

.rv-discrete-color-legend-item.horizontal .rv-discrete-color-legend-item__title {
  font-size: 15px;
  font-weight: bold;
}

.rv-discrete-color-legend-item.horizontal
  .rv-discrete-color-legend-item__color
  .rv-discrete-color-legend-item__color__path {
  transform: rotate(90deg);
}

.rv-xy-plot__axis__tick__text {
  font-size: 13px;
}

.risk-spread-table td {
  padding: 0.7rem 0.5rem;
}

.risk-spread-table td,
.risk-spread-table th {
  border: none;
}

.risk-spread-table td {
  opacity: 0.9;
}

.risk-spread-table th:not(.empty) {
  font-weight: bold;
  border-bottom: solid thin #212121;
}

.risk-spread-table tr .border-left {
  border: solid thin #212121;
}

.risk-spread-table tr td:last-child {
  border-right: solid thin #212121;
}

.risk-spread-table tr .border-right {
  border-right: solid thin #212121;
}

.risk-spread-table tr .border-bottom {
  border-bottom: solid thin #212121;
}

.risk-spread-table td[data-is-numeric="true"] {
  font-size: 1.1rem;
  border: solid thin #212121;
}
